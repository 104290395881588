*,
:before,
:after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e2e8f0
}

:before,
:after {
    --tw-content: ""
}

html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: 'Kumbh Sans',ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
    font-feature-settings: normal
}

body {
    margin: 0;
    line-height: inherit
}

hr {
    height: 0;
    color: inherit;
    border-top-width: 1px
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit
}

a {
    color: inherit;
    text-decoration: inherit
}

b,
strong {
    font-weight: bolder
}

code,
kbd,
samp,
pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0
}

button,
select {
    text-transform: none
}

button,
[type=button],
[type=reset]{
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none
}

:-moz-focusring {
    outline: auto
}

:-moz-ui-invalid {
    box-shadow: none
}

progress {
    vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0
}

fieldset {
    margin: 0;
    padding: 0
}

legend {
    padding: 0
}

ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0
}

textarea {
    resize: vertical
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    color: #94a3b8
}

input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #94a3b8
}

button,
[role=button] {
    cursor: pointer
}

:disabled {
    cursor: default
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle
}

img,
video {
    max-width: 100%;
    height: auto
}

[hidden] {
    display: none
}

*,
:before,
:after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(41 148 239 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(41 148 239 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

.navbar-active #hamburger div:first-child {
    --tw-translate-y: .375rem;
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.navbar-active #hamburger div:last-child {
    --tw-translate-y: -.25rem;
    --tw-rotate: -45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.navbar-active #layer {
    transform-origin: top;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.navbar-active #navlinks {
    visibility: visible;
    display: block;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    opacity: 1
}

@media (min-width: 1024px) {
    .navbar-active #navlinks {
        display: flex;
        --tw-translate-y: 0px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
}

.invisible {
    visibility: hidden
}

.fixed {
    position: fixed
}

.absolute {
    position: absolute
}

.relative {
    position: relative
}

.inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
}

.inset-x-0 {
    left: 0px;
    right: 0px
}

.top-full {
    top: 100%
}

.left-0 {
    left: 0px
}

.top-3 {
    top: .75rem
}

.right-14 {
    right: 3.5rem
}

.z-10 {
    z-index: 10
}

.z-20 {
    z-index: 20
}

.col-span-8 {
    grid-column: span 8 / span 8
}

.m-auto {
    margin: auto
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem
}

.-mr-6 {
    margin-right: -1.5rem
}

.mt-2 {
    margin-top: .5rem
}

.mt-12 {
    margin-top: 3rem
}

.-ml-1 {
    margin-left: -.25rem
}

.ml-auto {
    margin-left: auto
}

.mt-8 {
    margin-top: 2rem
}

.mb-12 {
    margin-bottom: 3rem
}

.ml-6 {
    margin-left: 1.5rem
}

.mb-20 {
    margin-bottom: 5rem
}

.mt-6 {
    margin-top: 1.5rem
}

.mb-6 {
    margin-bottom: 1.5rem
}

.mb-2 {
    margin-bottom: .5rem
}

.mt-1 {
    margin-top: .25rem
}

.mb-8 {
    margin-bottom: 2rem
}

.-mt-0\.5 {
    margin-top: -.125rem
}

.-mt-0 {
    margin-top: -0px
}

.mt-4 {
    margin-top: 1rem
}

.block {
    display: block
}

.inline-block {
    display: inline-block
}

.flex {
    display: flex
}

.grid {
    display: grid
}

.hidden {
    display: none
}

.h-0\.5 {
    height: .125rem
}

.h-0 {
    height: 0px
}

.h-screen {
    height: 100vh
}

.h-9 {
    height: 2.25rem
}

.h-5 {
    height: 1.25rem
}

.h-6 {
    height: 1.5rem
}

.h-12 {
    height: 3rem
}

.h-8 {
    height: 2rem
}

.h-7 {
    height: 1.75rem
}

.h-full {
    height: 100%
}

.h-28 {
    height: 7rem
}

.h-11 {
    height: 2.75rem
}

.max-h-0 {
    max-height: 0px
}

.min-h-screen {
    min-height: 100vh
}

.w-full {
    width: 100%
}

.w-5 {
    width: 1.25rem
}

.w-30{
width: 60%;
}


.w-screen {
    width: 100vw
}

.w-9 {
    width: 2.25rem
}

.w-6 {
    width: 1.5rem
}

.w-16 {
    width: 4rem
}

.w-max {
    width: -moz-max-content;
    width: max-content
}

.w-auto {
    width: auto
}

.w-12 {
    width: 3rem
}

.max-w-2xl {
    max-width: 42rem
}

.origin-bottom {
    transform-origin: bottom
}

.origin-top-right {
    transform-origin: top right
}

.translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-\[100\%\] {
    --tw-translate-y: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-1 {
    --tw-translate-y: .25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-0 {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-y-0 {
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.list-inside {
    list-style-position: inside
}

.grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}

.flex-col {
    flex-direction: column
}

.flex-wrap {
    flex-wrap: wrap
}

.items-start {
    align-items: flex-start
}

.items-center {
    align-items: center
}

.justify-end {
    justify-content: flex-end
}

.justify-center {
    justify-content: center
}

.justify-between {
    justify-content: space-between
}

.gap-6 {
    gap: 1.5rem
}

.gap-12 {
    gap: 3rem
}

.gap-4 {
    gap: 1rem
}

.space-x-2>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-6>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
}

.space-y-2>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse))
}

.space-y-8>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
}

.space-y-4>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.divide-y>:not([hidden])~:not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}

.divide-gray-200>:not([hidden])~:not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-divide-opacity))
}

.overflow-hidden {
    overflow: hidden
}

.overflow-x-hidden {
    overflow-x: hidden
}

.scroll-smooth {
    scroll-behavior: smooth
}

.rounded {
    border-radius: .25rem
}

.rounded-3xl {
    border-radius: 1.5rem
}

.rounded-full {
    border-radius: 9999px
}

.rounded-lg {
    border-radius: .5rem
}

.border {
    border-width: 1px
}

.border-y {
    border-top-width: 1px;
    border-bottom-width: 1px
}

.border-b {
    border-bottom-width: 1px
}

.border-t {
    border-top-width: 1px
}

.border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-border-opacity))
}

.border-primary\/10 {
    border-color: #1565c01a
}

.border-primary\/20 {
    border-color: #1565c033
}

.border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity))
}

.border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-white\/80 {
    background-color: #fffc
}

.bg-sky-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(12 74 110 / var(--tw-bg-opacity))
}

.bg-white\/70 {
    background-color: #ffffffb3
}

.bg-transparent {
    background-color: transparent
}

.bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}

.from-orange-400 {
    --tw-gradient-from: #fb923c;
    --tw-gradient-to: rgb(251 146 60 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-to: rgb(244 114 182 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-to: rgb(0 0 0 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.to-secondary {
    --tw-gradient-to: #7161ef
}

.bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text
}

.fill-gray-500 {
    fill: #64748b
}

.fill-\[\#24221B\] {
    fill: #24221b
}

.p-6 {
    padding: 1.5rem
}

.p-8 {
    padding: 2rem
}

.p-1 {
    padding: .25rem
}

.p-4 {
    padding: 1rem
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}

.px-2 {
    padding-left: .5rem;
    padding-right: .5rem
}

.py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem
}

.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
}

.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

.py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem
}

.py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.pt-32 {
    padding-top: 8rem
}

.pl-4 {
    padding-left: 1rem
}

.pr-12 {
    padding-right: 3rem
}

.pb-6 {
    padding-bottom: 1.5rem
}

.pb-8 {
    padding-bottom: 2rem
}

.pt-56 {
    padding-top: 14rem
}

.pb-16 {
    padding-bottom: 4rem
}

.text-left {
    text-align: left
}

.text-center {
    text-align: center
}

.font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem
}

.text-5xl {
    font-size: 3rem;
    line-height: 1
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
}

.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}

.font-medium {
    font-weight: 500
}

.font-semibold {
    font-weight: 600
}

.font-bold {
    font-weight: 700
}

.tracking-wide {
    letter-spacing: .025em
}

.tracking-wider {
    letter-spacing: .05em
}

.tracking-widest {
    letter-spacing: .1em
}

.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity))
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-blue-900 {
    --tw-text-opacity: 1;
    color: rgb(0 50 94 / var(--tw-text-opacity))
}

.text-gray-800 {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity))
}

.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity))
}

.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity))
}

.text-transparent {
    color: transparent
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity))
}

.text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity))
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity))
}

.placeholder-gray-600::-moz-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(71 85 105 / var(--tw-placeholder-opacity))
}

.placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(71 85 105 / var(--tw-placeholder-opacity))
}

.opacity-0 {
    opacity: 0
}

.opacity-80 {
    opacity: .8
}

.shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / .25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-gray-600\/10 {
    --tw-shadow-color: rgb(71 85 105 / .1);
    --tw-shadow: var(--tw-shadow-colored)
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
}

.brightness-75 {
    --tw-brightness: brightness(.75);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.contrast-200 {
    --tw-contrast: contrast(2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.grayscale {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.backdrop-blur {
    --tw-backdrop-blur: blur(8px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.duration-300 {
    transition-duration: .3s
}

.duration-500 {
    transition-duration: .5s
}

.duration-100 {
    transition-duration: .1s
}

.ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

#alertname{
    margin-top: 25px;
}

#alertname.visible {
    animation: pop 0.3s ease-in-out;
    opacity: 0.8;
  }

@keyframes pop {
    0% {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }


.st0 {
    fill: #957fef
}

.selection\:bg-primary\/10 *::-moz-selection {
    background-color: #1565c01a
}

.selection\:bg-primary\/10 *::selection {
    background-color: #1565c01a
}

.selection\:text-primary *::-moz-selection {
    --tw-text-opacity: 1;
    color: rgb(21 101 192 / var(--tw-text-opacity))
}

.selection\:text-primary *::selection {
    --tw-text-opacity: 1;
    color: rgb(21 101 192 / var(--tw-text-opacity))
}

.selection\:bg-primary\/10::-moz-selection {
    background-color: #1565c01a
}

.selection\:bg-primary\/10::selection {
    background-color: #1565c01a
}

.selection\:text-primary::-moz-selection {
    --tw-text-opacity: 1;
    color: rgb(21 101 192 / var(--tw-text-opacity))
}

.selection\:text-primary::selection {
    --tw-text-opacity: 1;
    color: rgb(21 101 192 / var(--tw-text-opacity))
}

.before\:absolute:before {
    content: var(--tw-content);
    position: absolute
}

.before\:inset-0:before {
    content: var(--tw-content);
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
}

.before\:rounded-full:before {
    content: var(--tw-content);
    border-radius: 9999px
}

.before\:border:before {
    content: var(--tw-content);
    border-width: 1px
}

.before\:border-gray-200:before {
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity))
}

.before\:bg-primary:before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(21 101 192 / var(--tw-bg-opacity))
}

.before\:bg-gray-50:before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity))
}

.before\:bg-gradient-to-b:before {
    content: var(--tw-content);
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}

.before\:transition-transform:before {
    content: var(--tw-content);
    transition-property: transform;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.before\:transition:before {
    content: var(--tw-content);
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.before\:duration-300:before {
    content: var(--tw-content);
    transition-duration: .3s
}

.invalid\:ring-2:invalid {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.invalid\:ring-red-400:invalid {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity))
}

.focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.hover\:text-primary:hover {
    --tw-text-opacity: 1;
    color: rgb(21 101 192 / var(--tw-text-opacity))
}

.hover\:before\:scale-105:hover:before {
    content: var(--tw-content);
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.active\:duration-75:active {
    transition-duration: 75ms
}

.active\:before\:scale-95:active:before {
    content: var(--tw-content);
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.group:hover .group-hover\:rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.group:hover .group-hover\:-rotate-90 {
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.group:hover .group-hover\:fill-yellow-400 {
    fill: #facc15
}

.group:hover .group-hover\:fill-blue-900 {
    fill: #00325e
}

.peer:invalid~.peer-invalid\:block {
    display: block
}

.dark .dark\:block {
    display: block
}

.dark .dark\:hidden {
    display: none
}

.dark .dark\:divide-gray-800>:not([hidden])~:not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(30 41 59 / var(--tw-divide-opacity))
}

.dark .dark\:border-gray-700\/30 {
    border-color: #3341554d
}

.dark .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-border-opacity))
}

.dark .dark\:border-white\/10 {
    border-color: #ffffff1a
}

.dark .dark\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgb(30 41 59 / var(--tw-border-opacity))
}

.dark .dark\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-900\/80 {
    background-color: #0f172acc
}

.dark .dark\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-900\/70 {
    background-color: #0f172ab3
}

.dark .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity))
}

.dark .dark\:bg-dark {
    --tw-bg-opacity: 1;
    background-color: rgb(10 16 30 / var(--tw-bg-opacity))
}

.dark .dark\:to-gray-900\/80 {
    --tw-gradient-to: rgb(15 23 42 / .8)
}

.dark .dark\:fill-gray-300 {
    fill: #cbd5e1
}

.dark .dark\:fill-gray-400 {
    fill: #94a3b8
}

.dark .dark\:fill-white {
    fill: #fff
}

.dark .dark\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(203 213 225 / var(--tw-text-opacity))
}

.dark .dark\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity))
}

.dark .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.dark .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity))
}

.dark .dark\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity))
}

.dark .dark\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity))
}

.dark .dark\:placeholder-white::-moz-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(255 255 255 / var(--tw-placeholder-opacity))
}

.dark .dark\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(255 255 255 / var(--tw-placeholder-opacity))
}

.dark .dark\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.dark .dark\:brightness-200 {
    --tw-brightness: brightness(2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.dark .dark\:contrast-0 {
    --tw-contrast: contrast(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.dark .dark\:contrast-100 {
    --tw-contrast: contrast(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.dark .dark\:grayscale {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.dark .dark\:invert {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.dark .dark\:before\:border-gray-700:before {
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-border-opacity))
}

.dark .dark\:before\:bg-primaryLight:before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(66 165 245 / var(--tw-bg-opacity))
}

.dark .dark\:before\:bg-gray-800:before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity))
}

.dark .dark\:hover\:text-primaryLight:hover {
    --tw-text-opacity: 1;
    color: rgb(66 165 245 / var(--tw-text-opacity))
}

.dark .dark\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

@media (min-width: 640px) {
    .sm\:right-24 {
        right: 6rem
    }
    .sm\:mx-auto {
        margin-left: auto;
        margin-right: auto
    }
    .sm\:mt-20 {
        margin-top: 5rem
    }
    .sm\:w-11\/12 {
        width: 91.666667%
    }
    .sm\:w-auto {
        width: auto
    }
    .sm\:max-w-lg {
        max-width: 32rem
    }
    .sm\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }
    .sm\:flex-row {
        flex-direction: row
    }
    .sm\:justify-between {
        justify-content: space-between
    }
    .sm\:p-12 {
        padding: 3rem
    }
    .sm\:px-12 {
        padding-left: 3rem;
        padding-right: 3rem
    }
    .sm\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem
    }
    .sm\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }
    .sm\:pt-40 {
        padding-top: 10rem
    }
    .sm\:text-6xl {
        font-size: 3.75rem;
        line-height: 1
    }
    .sm\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem
    }
    .sm\:text-5xl {
        font-size: 3rem;
        line-height: 1
    }
}

@media (min-width: 768px) {
    .md\:col-span-2 {
        grid-column: span 2 / span 2
    }
    .md\:col-span-6 {
        grid-column: span 6 / span 6
    }
    .md\:mx-auto {
        margin-left: auto;
        margin-right: auto
    }
    .md\:mt-12 {
        margin-top: 3rem
    }
    .md\:block {
        display: block
    }
    .md\:hidden {
        display: none
    }
    .md\:w-max {
        width: -moz-max-content;
        width: max-content
    }
    .md\:w-4\/5 {
        width: 80%
    }
    .md\:w-5\/6 {
        width: 83.333333%
    }
    .md\:flex-col {
        flex-direction: column
    }
    .md\:items-start {
        align-items: flex-start
    }
    .md\:justify-between {
        justify-content: space-between
    }
    .md\:gap-0 {
        gap: 0px
    }
    .md\:space-y-6>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
    }
    .md\:border-none {
        border-style: none
    }
    .md\:p-2 {
        padding: .5rem
    }
    .md\:px-0 {
        padding-left: 0;
        padding-right: 0
    }
    .md\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem
    }
    .md\:py-12 {
        padding-top: 3rem;
        padding-bottom: 3rem
    }
    .md\:py-0 {
        padding-top: 0;
        padding-bottom: 0
    }
    .md\:pt-48 {
        padding-top: 12rem
    }
    .md\:pr-1\.5 {
        padding-right: .375rem
    }
    .md\:pr-1 {
        padding-right: .25rem
    }
    .md\:pl-16 {
        padding-left: 4rem
    }
    .md\:text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem
    }
    .md\:text-5xl {
        font-size: 3rem;
        line-height: 1
    }
    .md\:text-6xl {
        font-size: 3.75rem;
        line-height: 1
    }
}

@media (min-width: 1024px) {
    .lg\:visible {
        visibility: visible
    }
    .lg\:relative {
        position: relative
    }
    .lg\:col-span-3 {
        grid-column: span 3 / span 3
    }
    .lg\:col-span-5 {
        grid-column: span 5 / span 5
    }
    .lg\:mt-0 {
        margin-top: 0
    }
    .lg\:mr-6 {
        margin-right: 1.5rem
    }
    .lg\:mr-auto {
        margin-right: auto
    }
    .lg\:-mt-8 {
        margin-top: -2rem
    }
    .lg\:flex {
        display: flex
    }
    .lg\:hidden {
        display: none
    }
    .lg\:flex-row-reverse {
        flex-direction: row-reverse;
    }
    .lg\:h-10 {
        height: 2.5rem
    }
    .lg\:w-max {
        width: -moz-max-content;
        width: max-content
    }
    .lg\:w-auto {
        width: auto
    }
    .lg\:w-6\/12 {
        width: 50%
    }
    .lg\:w-2\/3,
    .lg\:w-4\/6 {
        width: 66.666667%
    }
    .lg\:max-w-max {
        max-width: -moz-max-content;
        max-width: max-content
    }
    .lg\:translate-y-0 {
        --tw-translate-y: 0px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }
    .lg\:scale-100 {
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }
    .lg\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
    .lg\:flex-row {
        flex-direction: row
    }
    .lg\:items-center {
        align-items: center
    }
    .lg\:gap-0 {
        gap: 0px
    }
    .lg\:gap-24 {
        gap: 6rem
    }
    .lg\:gap-x-24 {
        -moz-column-gap: 6rem;
        column-gap: 6rem
    }
    .lg\:space-y-0>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * var(--tw-space-y-reverse))
    }
    .lg\:border-l {
        border-left-width: 1px
    }
    .lg\:border-none {
        border-style: none
    }
    .lg\:bg-transparent {
        background-color: transparent
    }
    .lg\:p-0 {
        padding: 0
    }
    .lg\:py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem
    }
    .lg\:px-12 {
        padding-left: 3rem;
        padding-right: 3rem
    }
    .lg\:pr-4 {
        padding-right: 1rem
    }
    .lg\:pl-6 {
        padding-left: 1.5rem
    }
    .lg\:pr-3 {
        padding-right: .75rem
    }
    .lg\:pl-5 {
        padding-left: 1.25rem
    }
    .lg\:pr-0 {
        padding-right: 0
    }
    .lg\:pt-56 {
        padding-top: 14rem
    }
    .lg\:text-left {
        text-align: left
    }
    .lg\:text-sm {
        font-size: .875rem;
        line-height: 1.25rem
    }
    .lg\:text-7xl {
        font-size: 4.5rem;
        line-height: 1
    }
    .lg\:text-8xl {
        font-size: 6rem;
        line-height: 1
    }
    .lg\:text-primary {
        --tw-text-opacity: 1;
        color: rgb(21 101 192 / var(--tw-text-opacity))
    }
    .lg\:opacity-100 {
        opacity: 1
    }
    .lg\:shadow-none {
        --tw-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }
    .lg\:before\:border:before {
        content: var(--tw-content);
        border-width: 1px
    }
    .lg\:before\:border-gray-200:before {
        content: var(--tw-content);
        --tw-border-opacity: 1;
        border-color: rgb(226 232 240 / var(--tw-border-opacity))
    }
    .lg\:before\:bg-gray-100:before {
        content: var(--tw-content);
        --tw-bg-opacity: 1;
        background-color: rgb(241 245 249 / var(--tw-bg-opacity))
    }
    .dark .dark\:lg\:bg-transparent {
        background-color: transparent
    }
    .dark .lg\:dark\:text-white {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity))
    }
    .dark .lg\:dark\:before\:bg-gray-800:before {
        content: var(--tw-content);
        --tw-bg-opacity: 1;
        background-color: rgb(30 41 59 / var(--tw-bg-opacity))
    }
}

